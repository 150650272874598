import { environment } from '../../../environments/environment';
import { assemblyInfo} from 'src/app/models/assemblyInfo';
import { HttpClient} from '@angular/common/http';
import { Injectable } from '@angular/core';

const API_URL = `${environment.apiUrl}/AssemblyInfo`;

@Injectable({
    providedIn: 'root'
})
export class AssemblyInfoService {


    constructor(
        private http: HttpClient,
    ) {
    }

    ngOnInit() {

    }

    getBasVersion(){

        return this.http.get<assemblyInfo>(API_URL);

    }
}