import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; // this is needed!
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateService, TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CookieService } from 'ngx-cookie-service';

import { AppComponent } from './app.component';

import { CoreModule } from './core/core.module';
import { LayoutModule } from './modules/layout/layout.module';
import { SharedModule } from './shared/shared.module';
import { RoutesModule } from './routes/routes.module';

import localeES from '@angular/common/locales/es';
import { registerLocaleData, KeyValuePipe } from '@angular/common';

import { SettingsService } from './core/settings/settings.service';
import { MenuService } from './core/menu/menu.service';
import { AuthService } from './services/auth/auth.service';
import { CreateUserComponent } from './modules/user/create-user/create-user.component';

registerLocaleData(localeES, 'es');

const configInitializer = (appConfig: SettingsService) => {
    return () => {
        appConfig.loadAppConfig();
    };
};

// https://github.com/ocombe/ng2-translate/issues/218
export function createTranslateLoader(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule, // required for ng2-tag-input
        CoreModule,
        LayoutModule,
        SharedModule.forRoot(),
        RoutesModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        KeyValuePipe,
        CookieService,
        {
            provide: APP_INITIALIZER,
            useFactory: configInitializer,
            multi: true,
            deps: [
                SettingsService
            ]
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
